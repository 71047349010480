import React from "react";
import {OurMissionVisionContainer} from "../../style";
import knowledgeBackground from "../../assets/images/knowledge-background.svg";

const OurMissionVision = () => {
  const missionTextPara = [
    `Provide easy to consume cancer
    awareness content for preventive,
    curative and palliative care.`,
   `Bring together a multidisciplinary team 
    to collaborate on all things oncology.`,
    `Educate and certify aspiring 
    oncologists, clinicians and the larger 
    provider community.`,
    `Connect global and local renowned 
    cancer care professionals and 
    institutions.`,
    `Drive insights to latest cancer care 
    technologies, trends, research and 
    clinical trials.`
  ];

  return (
    <OurMissionVisionContainer className="grid-container">
      <div className="grid photo">
        <img src={knowledgeBackground} alt="mission vision"/>
      </div>
      <div className="grid mission">
        <h2 className="heading">Our mission</h2>
        {
          missionTextPara.map((el, index) => (
            <p key={`para-${index}`}>{el}</p>
          ))
        }
      </div>
      <div className="grid vision">
      <h2 className="heading">Our vision</h2>
        <p>
          To be the oncology education and
          knowledge platform of choice for 
          patients, cancer care providers 
          and healthcare professionals.
        </p>
      </div>
    </OurMissionVisionContainer>
  );
};

export default OurMissionVision;