import React from "react";
import {CenteredContainer} from "../../style";

const Home = () => {
  return (
    <CenteredContainer>
      <h2 className="heading-text">
        <span>
          <i className="blue">C</i>reative <i className="green">P</i>ortal
          for <i className="pink">O</i>ncology <i className="violet">E</i>ducation and <i className="sky">M</i>ore
        </span>
      </h2>
      <div className="small-text">
        A non-profit initiative by practicing oncologists to help spread 
        knowledge about cancer, its treatment and care.
        Led by renowned experts and leaders in Oncology in 
        collaboration with the some of the best oncology centres
        in the world.
      </div>
    </CenteredContainer>
  );
};

export default Home;