import React from "react";
import {colors, FullWidthContainer, OurGoalContainer} from "../../style";

import accessToAll from "../../assets/images/access-to-all.svg";
import engageEvents from "../../assets/images/engage-events.svg";
import globalNetwork from "../../assets/images/global-network.svg";
import cancerAwareness from "../../assets/images/cancer-awareness.svg";
import knowledge from "../../assets/images/knowledge-beyond-boundaries.svg";
import globalCertification from "../../assets/images/global-certification.svg";

const OurGoal = () => {
  const goalSteps = [{
    photo: knowledge,
    heading: "Knowledge beyond boundaries",
    desc: `Online events and education opportunities that connects oncology professionals in emerging markets
    with practitioners, research, processes, technologies and solutions from the developed world.`,
    border: colors.blue
  }, {
    photo: accessToAll,
    heading: "Access for all",
    desc: `Frequent interactions with top notch and highly prolific speakers from across the globe
    and India to update and educate practicing clinicians in India.`,
    border: colors.green
  }, {
    photo: globalNetwork,
    heading: "A global network",
    desc: `Virtual and frequent networking opportunities with oncology professionals from multiple
    disciplines across the world.`,
    border: colors.violet
  }, {
    photo: cancerAwareness,
    heading: "Cancer awareness and support for all",
    desc: `Independent, unbiased and multilingual knowledge support for cancer patients around
    India to help build awareness throughout the treatment journey.`,
    border: colors.pink
  }, {
    photo: globalCertification,
    heading: "Global certification programs",
    desc: `Enabling aspiring oncologists to learn and equip themselves with 
    certification programs from globally renowned institutions.`,
    border: colors.sky
  }, {
    photo: engageEvents,
    heading: "Events that engage and educate",
    desc: `Gamified medical education events with pre-test and post-test questionnaires to engage
    attendees and make the events and learning more thought-provoking.`,
    border: colors.yellow
  }];

  return (
    <FullWidthContainer className="our-goal text-center">
      <h2 className="section-heading">Our Goal</h2>
      {
        goalSteps.map(el => {
          const {photo, heading, desc, border} = el;
          return (
            <OurGoalContainer key={heading} border={border}>
              <img src={photo} alt="goal details"/>
              <h3 className="goal-heading">{heading}</h3>
              <p className="goal-desc">{desc}</p>
            </OurGoalContainer>
          );
        })
      }
    </FullWidthContainer>
  )
};

export default OurGoal;