import React from "react";
import {GlobalStyle, MainContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";

import Home from "../components/about-us/home";
import OurGoal from "../components/about-us/ourGoal";
import OurMissionVision from "../components/about-us/ourMissionVision";

const AboutUs = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <Home/>
      <OurMissionVision/>
      <OurGoal/>
      <FooterBar/>
    </MainContainer>
  );
};

export default AboutUs;